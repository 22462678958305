/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import emotionReset from "emotion-reset"
import { globalFonts } from "../styles/fonts/fontFamilyDefinition"
import Header from "./Partials/header"
import Footer from "./Partials/Footer"
import { BodyTransition } from "./Transition/transitions"
import VerticalSpacer from "../components/Partials/VerticalSpacer"

export const MyContext = React.createContext()

const Layout = props => {
    const [tanFooter, toggleFooterColor] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)

    console.log("location", location)

    return (
        <>
            <Global
                styles={css`
                    ${globalFonts}
                    ${emotionReset}

                    *, *:before, *:after {
                        box-sizing: border-box;
                    }
                    html {
                        box-sizing: border-box;
                        font-size: 62.5%;
                        font-family: "Manrope";
                    }
                    body {
                        line-height: 1.5;
                        letter-spacing: 0;
                        background-color: #f8f7f5;
                        -moz-osx-font-smoothing: grayscale;
                        text-rendering: optimizeLegibility;
                        transition: background-color 0.3s ease-in;
                        will-change: background-color, color;
                    }
                    .about-page {
                        background-color: #4c01d7;
                    }
                    .clients-page {
                        background-color: #280764;
                    }
                    .curacao-page {
                        background: linear-gradient(
                            90deg,
                            rgba(238, 37, 149, 1) 0%,
                            rgba(255, 222, 79, 1) 100%
                        );
                    }
                    .drive-shack-page {
                        background-color: #f7e665;
                    }
                    .iis-page {
                        background-color: #44b8c1;
                    }
                    .ofo-page {
                        background-color: #f1f1f1;
                    }
                    .john-guest-page {
                        background-color: #00aeef;
                    }
                    .case-studies-page {
                        background-color: #171717;
                    }
                    @-moz-document url-prefix() {
                        body .with-ff-fix {
                            font-weight: lighter !important;
                        }
                    }
                `}
            />
            <MyContext.Provider value={{ tanFooter, toggleFooterColor }}>
                <Header
                    location={props.location}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                />
                <VerticalSpacer size="xxxl" />
                <BodyTransition {...props}>
                    <Main className={menuOpen ? "menu-open" : "menu-closed"}>
                        {props.children}
                    </Main>
                </BodyTransition>
                <VerticalSpacer size="xxl" />
                <Footer tanFooter={tanFooter} />
            </MyContext.Provider>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout

const Main = styled.main`
    &.menu-open {
        #home-logo {
            display: none;
        }
    }
`
