export const size = {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "425px",
    mobileXL: "500px",
    tablet: "768px",
    laptop: "1024px",
    laptopM: "1200px",
    laptopL: "1440px",
    laptopXL: "1700px",
    desktop: "2560px"
}
export const deviceMinW = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    mobileXL: `(min-width: ${size.mobileXL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopM: `(min-width: ${size.laptopM})`,
    laptopL: `(min-width: ${size.laptopL})`,
    laptopXL: `(min-width: ${size.laptopXL})`,
    desktop: `(min-width: ${size.desktop})`
}
