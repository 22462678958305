export const spacer = Object.freeze({
    xxs: "0.5rem", //5px
    xs: "0.8rem", //8px
    s: "1rem", //10px
    m: "2rem", // 20px
    l: "4rem", // 40px
    xl: "8rem", // 80px
    xxl: "16rem", //160px
    xxxl: "28rem" // 280px
})
