import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { colors } from "../../styles/colors"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { fontSize } from "../../styles/fonts/fontStyle"
import { useStaticQuery, graphql } from "gatsby"
import { get } from "lodash"
import VerticalSpacer from "./VerticalSpacer"
import { LinkWrapper } from "./Typography"
import { spacer } from "../../styles/sizes"
import { deviceMinW } from "../../styles/mediaQueries"

const ContactInfo = ({ tanFooter }) => {
    const footerQuery = useStaticQuery(
        graphql`
            query {
                allContactInfoJson {
                    nodes {
                        contactInformation {
                            id
                            title
                            email
                            phone
                        }
                    }
                }
            }
        `
    )

    const [footerInfo] = get(footerQuery, "allContactInfoJson.nodes", [])
    const { contactInformation = [] } = footerInfo
    const doesPhoneNumberExist = !!contactInformation.phone

    return (
        <>
            <ContactInfoContainer>
                {contactInformation.map(contactInfo => (
                    <ContactInfoWrapper key={contactInfo.id}>
                        <ContactCategory tanFooter={tanFooter}>
                            {contactInfo.title}
                        </ContactCategory>
                        <LinkWrapper>
                            <ContactLink
                                href={`mailto:${contactInfo.email}`}
                                tanFooter={tanFooter}
                                aria-label="Click here to email Descriptive."
                            >
                                {contactInfo.email}
                            </ContactLink>
                        </LinkWrapper>
                        {!doesPhoneNumberExist && (
                            <LinkWrapper>
                                <VerticalSpacer size="xs" />
                                <ContactLink
                                    href={`tel:${contactInfo.phone}`}
                                    tanFooter={tanFooter}
                                    aria-label="Click here to call Descriptive."
                                >
                                    {contactInfo.phone}
                                </ContactLink>
                            </LinkWrapper>
                        )}
                        <VerticalSpacer size="xs" />
                    </ContactInfoWrapper>
                ))}
            </ContactInfoContainer>
        </>
    )
}
export default ContactInfo

const ContactInfoContainer = styled.ul`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 4rem;
    padding: ${spacer.l} 0;

    @media ${deviceMinW.tablet} {
        grid-template-columns: repeat(2, 1fr);
    }
`

const ContactInfoWrapper = styled.li`
    display: flex;
    flex-direction: column;
`

const ContactCategory = styled.div`
    font-family: ${fontFamily.manrope};
    font-weight: 700;
    font-size: ${fontSize.longPrimer};
    margin-bottom: ${spacer.s};
    color: ${colors.codGray};

    ${({ tanFooter }) => css`
        color: ${tanFooter && colors.pampas};
    `};
`

const ContactLink = styled.a`
    text-decoration: none;
    font-family: ${fontFamily.manrope};
    font-weight: 500;
    color: ${colors.codGray};
    font-size: ${fontSize.brevier};

    ${({ tanFooter }) => css`
        color: ${tanFooter && colors.pampas};
    `};
`
