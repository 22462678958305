export const fontSize = Object.freeze({
    minion: "1.2rem", // 12px //
    brevier: "1.4rem", // 14px //
    longPrimer: "1.6rem", // 16px //
    pica: "1.8rem", // 18px // Index links, titles & headlines
    bodyCopy: "1.8rem", // 18px //Body copy
    greatPrimer: "2.4rem", // 24px //
    doublePica: "2.5rem", // 25px //Subhead or article introduction
    paragon: "2.8rem", // 28px //
    trafalgar: "2.8rem", // 28px //
    canon: "3.6rem", // 36px // Blog post title & hom
    foolscap: "5.6rem", // 56px // Hero text
    royal: "7.6rem" // 76px // Homepage stylistic body copy
})

export const lineHeight = Object.freeze({
    minion: "1.6rem", // 16px 1.4x
    brevier: "1.9rem", // 19px 1.4x
    longPrimer: "2.2rem", //22px 1.4x
    pica: "2.5rem", //25px 1.4x
    bodyCopy: "2.5rem", // 25px 1.4ex
    greatPrimer: "3.3rem", // 33px 1.4x
    doublePica: "3.5rem", // 35px 1.4x
    paragon: "3.3rem", // 24px
    trafalgar: "3.3rem", // 33px
    canon: "4.3rem", // 43px
    foolscap: "6.7rem", // 67px
    royal: "9.1rem" // 91px
})
