import styled from "@emotion/styled"
import { deviceMinW } from "./mediaQueries"
import { spacer } from "../styles/sizes"

const AppContainer = styled.div`
    margin: 0 auto;
    padding: 0 ${spacer.m};

    @media ${deviceMinW.tablet} {
        max-width: 1400px;
        padding: 0 ${spacer.l};
    }
`

export default AppContainer
