import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import ContactInfo from "./ContactInfo"
import SocialLinks from "./SocialLinks"
import AddressInfo from "./AddressInfo"
import RightContainer from "./RightContainer"
import AppContainer from "../../styles/AppContainer"
import { deviceMinW } from "../../styles/mediaQueries"
import { spacer } from "../../styles/sizes"
import { colors } from "../../styles/colors"

const Footer = ({ tanFooter }) => {
    return (
        <FooterTag id="footer">
            <AppContainer>
                <FooterWrapper>
                    <RightContainer isFooter>
                        <BottomFooter tanFooter={tanFooter}>
                            <ContactInfo tanFooter={tanFooter} />
                            <BottomRow>
                                <SocialLinks tanFooter={tanFooter} />
                                <AddressInfo tanFooter={tanFooter} />
                            </BottomRow>
                        </BottomFooter>
                    </RightContainer>
                </FooterWrapper>
            </AppContainer>
        </FooterTag>
    )
}
export default Footer

const FooterTag = styled.footer``

const FooterWrapper = styled.div`
    display: flex;
    justify-content: center;

    @media ${deviceMinW.mobileXL} {
        justify-content: flex-end;
    }
`
const BottomFooter = styled.div`
    flex-basis: 100%;
    padding-bottom: ${spacer.m};
    border-top: 2px solid ${colors.black};

    ${({ tanFooter }) => css`
        border-top: ${tanFooter && `2px solid ${colors.pampas}`};
    `};
`

const BottomRow = styled.div`
    display: flex;
    flex-direction: column;

    @media ${deviceMinW.laptop} {
        flex-direction: row;
        grid-gap: 16px;
    }
`
