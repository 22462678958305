export const colors = {
    codGray: "#171717",
    pampas: "#F8F7F5",
    electricViolet: "#4C01D7",
    wisteria: "#B396DB",
    persianIndigo: "#280764",
    broom: "#f8ff1a",
    silver: "#d8d8d8",
    black: "#060606"
}
