// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-curacao-js": () => import("./../../../src/pages/case-studies/curacao.js" /* webpackChunkName: "component---src-pages-case-studies-curacao-js" */),
  "component---src-pages-case-studies-iis-js": () => import("./../../../src/pages/case-studies/iis.js" /* webpackChunkName: "component---src-pages-case-studies-iis-js" */),
  "component---src-pages-case-studies-john-guest-js": () => import("./../../../src/pages/case-studies/john-guest.js" /* webpackChunkName: "component---src-pages-case-studies-john-guest-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-studies-ofo-js": () => import("./../../../src/pages/case-studies/ofo.js" /* webpackChunkName: "component---src-pages-case-studies-ofo-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practice-areas-js": () => import("./../../../src/pages/practice-areas.js" /* webpackChunkName: "component---src-pages-practice-areas-js" */)
}

