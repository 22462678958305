import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import { bool, func } from "prop-types"
import { get } from "lodash"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { fontSize } from "../../styles/fonts/fontStyle"
import { colors } from "../../styles/colors"
import { deviceMinW } from "../../styles/mediaQueries"
import { zIndex } from "../../styles/zIndex"
import { spacer } from "../../styles/sizes"
import logo from "../../images/descriptive-logo-purple.svg"

const Menu = ({ menuOpen, setMenuOpen, isOpen }) => {
    const navigationQuery = useStaticQuery(
        graphql`
            query {
                allNavigationJson {
                    nodes {
                        id
                        label
                        path
                    }
                }
            }
        `
    )

    const navigationItems = get(navigationQuery, "allNavigationJson.nodes", [])

    return (
        <>
            <MobileNavBar
                menuOpen={menuOpen}
                id="mobile-menu-container"
                isOpen={isOpen}
            >
                <MobileLogoLink to="/">
                    <NavLogo
                        src={logo}
                        alt="Descriptive logo"
                        onClick={() => setMenuOpen(!menuOpen)}
                    />
                </MobileLogoLink>
                <MobileListWrapper id="no-scroll-lock">
                    <MobileList>
                        {navigationItems.map(navLink => {
                            return (
                                <NavListItem key={navLink.id}>
                                    <NavLink
                                        to={navLink.path}
                                        activeClassName="active"
                                        onClick={() => setMenuOpen(!menuOpen)}
                                    >
                                        {navLink.label}
                                    </NavLink>
                                </NavListItem>
                            )
                        })}
                    </MobileList>
                </MobileListWrapper>
            </MobileNavBar>
        </>
    )
}

Menu.propTypes = {
    menuOpen: bool.isRequired,
    setMenuOpen: func.isRequired
}

export default Menu

const MobileNavBar = styled.div`
    width: 100%;
    transform: translateY(-100%);
    background: ${colors.wisteria};
    display: inline-block;
    height: 100vh;
    text-align: left;
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${zIndex.mobileNav};
    transition: transform 0.3s ease-in;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    will-change: transform;

    ${({ menuOpen }) =>
        css`
            transform: ${menuOpen && `translateY(0)`};
        `}

    @media ${deviceMinW.tablet} {
        display: none;
    }


`

const MobileListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16rem;
`

const MobileList = styled.ul`
    display: flex;
    list-style: none;
    height: 100%;
    flex-direction: column;
    padding: 0 ${spacer.m};
`
const NavListItem = styled.li`
    padding-bottom: ${spacer.l};

    &:last-of-type {
        padding-bottom: 0;
    }
`

const NavLink = styled(Link)`
    display: inline-block;
    align-items: center;
    font-family: ${fontFamily.manrope};
    font-weight: 500;
    color: ${colors.electricViolet};
    text-decoration: none;
    font-size: ${fontSize.trafalgar};
    transition: color 0.3s linear;
    position: relative;
    white-space: nowrap;

    &.active {
        &:after {
            content: "";
            width: ${spacer.xs};
            height: ${spacer.xs};
            border-radius: ${spacer.xs};
            background-color: ${colors.electricViolet};
            position: absolute;
            right: -2rem;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
`
const NavLogo = styled.img`
    width: 10rem;
`

const MobileLogoLink = styled(Link)`
    position: relative;
    display: inline-block;
    transform: translateY(77%);
    padding-left: 2rem;
`
