import styled from "@emotion/styled"
import { deviceMinW } from "../../styles/mediaQueries"
import { css } from "@emotion/core"

const RightContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;

    @media ${deviceMinW.mobileXL} {
        justify-content: flex-end;
        flex-basis: calc(100% * 3 / 4);
        max-width: calc(100% * 3 / 4);
        width: calc(100% * 3 / 4);
    }

    @media ${deviceMinW.laptop} {
        justify-content: flex-end;
        flex-basis: calc(100% * 4 / 5);
        max-width: calc(100% * 4 / 5);
        width: calc(100% * 4 / 5);
    }

    ${props =>
        props.isFooter &&
        css`
                flex-direction: column;
                display: flex;
                width: 100%;
                flex-basis: 100%;
                max-width: 100%;

            /* @media ${deviceMinW.mobileL} {
                display: flex;
                width: 100%;
                flex-basis: 100%;
                max-width: 100%;
            } */


            @media ${deviceMinW.mobileXL} {
                justify-content: flex-end;
                flex-basis: calc(100% * 3 / 4);
                max-width: calc(100% * 3 / 4);
                width: calc(100% * 3 / 4);
            }


            @media ${deviceMinW.laptop} {
                flex-direction: row-reverse;
                justify-content: flex-end;
                flex-basis: calc(100% * 4 / 5);
                max-width: calc(100% * 4 / 5);
                width: calc(100% * 4 / 5);
            }
        `}
`
export default RightContainer
