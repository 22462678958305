import React from "react"
import styled from "@emotion/styled"
import { bool, func } from "prop-types"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { fontSize } from "../../styles/fonts/fontStyle"
import { colors } from "../../styles/colors"
import { deviceMinW } from "../../styles/mediaQueries"
import { zIndex } from "../../styles/zIndex"
import { spacer } from "../../styles/sizes"

const Burger = ({
    menuOpen,
    setMenuOpen,
    aboutPath,
    clientsPath,
    caseStudiesPath
}) => {
    return (
        <>
            <HamburgerButton
                menuOpen={menuOpen}
                onClick={() => setMenuOpen(!menuOpen)}
                aboutPath={aboutPath}
                clientsPath={clientsPath}
                caseStudiesPath={caseStudiesPath}
                aria-label="Menu"
            >
                <BurgerLine
                    aboutPath={aboutPath}
                    clientsPath={clientsPath}
                    caseStudiesPath={caseStudiesPath}
                />
                <BurgerLine
                    aboutPath={aboutPath}
                    clientsPath={clientsPath}
                    caseStudiesPath={caseStudiesPath}
                />
            </HamburgerButton>
        </>
    )
}

Burger.propTypes = {
    menuOpen: bool.isRequired,
    setMenuOpen: func.isRequired
}

export default Burger

const HamburgerButton = styled.button`
    position: relative;
    font-size: ${fontSize.longPrimer};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: ${spacer.l};
    height: 1.5rem;
    background: transparent;
    border: none;
    padding: 0;
    font-family: ${fontFamily.manrope};
    font-weight: 600;
    align-items: center;
    cursor: pointer;
    pointer-events: all;
    position: fixed;
    z-index: ${zIndex.burger};
    top: 29px;
    right: 10px;

    &:focus {
        outline: none;
    }

    div {
        width: ${spacer.m};
        height: 2px;
        background: ${({ aboutPath, clientsPath, caseStudiesPath, menuOpen }) =>
            menuOpen
                ? colors.electricViolet
                : aboutPath || clientsPath || caseStudiesPath
                ? colors.pampas
                : colors.codGray};

        transition: all 0.3s linear;
        position: relative;
        transform-origin: 4.5px;

        &:first-of-type {
            transform: ${({ menuOpen }) =>
                menuOpen ? "rotate(45deg)" : "rotate(0)"};
        }
        &:last-of-type {
            transform: ${({ menuOpen }) =>
                menuOpen ? "rotate(-45deg)" : "rotate(0)"};
        }
    }
    @media ${deviceMinW.tablet} {
        display: none;
    }
`

const BurgerLine = styled.div``
