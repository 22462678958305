import React from "react"
import { motion, AnimatePresence } from "framer-motion"

export const BodyTransition = ({ children, location }) => {
    const duration = 0.4

    const variants = {
        initial: {
            y: 10,
            opacity: 0
        },
        enter: {
            opacity: 1,
            y: 0,
            transition: {
                duration: duration,
                delay: duration,
                when: "beforeChildren",
                ease: "easeIn"
            }
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.2 }
        }
    }

    return (
        <AnimatePresence exitBeforeEnter>
            <motion.div
                key={location.pathname}
                variants={variants}
                initial="initial"
                animate="enter"
                exit="exit"
            >
                {children}
            </motion.div>
        </AnimatePresence>
    )
}
