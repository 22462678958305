import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { colors } from "../../styles/colors"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { fontSize } from "../../styles/fonts/fontStyle"
import VerticalSpacer from "./VerticalSpacer"
import { LinkWrapper } from "./Typography"
import { spacer } from "../../styles/sizes"
import { deviceMinW } from "../../styles/mediaQueries"

const AddressInfo = ({ tanFooter }) => {
    return (
        <>
            <AddressInfoContainer tanFooter={tanFooter}>
                <LinkWrapper>
                    <Address
                        tanFooter={tanFooter}
                        href="https://goo.gl/maps/SbK5qX9ZyuRiB9Ma9"
                        aria-label="Click here to email Descriptive."
                        target="_blank"
                    >
                        53 Bridge St, Suite 301, Brooklyn, NY 11201
                    </Address>
                </LinkWrapper>
            </AddressInfoContainer>
        </>
    )
}
export default AddressInfo

const AddressInfoContainer = styled.div`
    margin-top: 24px;

    @media ${deviceMinW.laptop} {
        margin-top: 0;
        border-left: 1px solid ${colors.black};

        ${({ tanFooter }) => css`
            border-left: ${tanFooter && `1px solid ${colors.pampas}`};
        `};
    }
`

const Address = styled.a`
    font-family: ${fontFamily.manrope};
    font-weight: 600;
    font-size: ${fontSize.minion};
    text-decoration: none;
    color: ${colors.codGray};

    ${({ tanFooter }) => css`
        color: ${tanFooter && colors.pampas};
    `};

    @media ${deviceMinW.laptop} {
        margin-left: 16px;
    }
`
