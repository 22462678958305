import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { deviceMinW } from "../../styles/mediaQueries"
import { colors } from "../../styles/colors"
import ReactHtmlParser from "react-html-parser"
import RightContainer from "../Partials/RightContainer"
import { spacer } from "../../styles/sizes"
import { fontSize } from "../../styles/fonts/fontStyle"

export const HeaderOne = ({ copy, isTan }) => {
    return (
        <>
            <H1 isTan={isTan}>{ReactHtmlParser(copy)}</H1>
        </>
    )
}

export const HeaderTwo = ({ copy }) => {
    return (
        <>
            <H2>{ReactHtmlParser(copy)}</H2>
        </>
    )
}

export const HeaderThree = ({ copy, isBold, isTan, isAbout }) => {
    return (
        <>
            <H3 isBold={isBold} isTan={isTan} isAbout={isAbout}>
                {ReactHtmlParser(copy)}
            </H3>
        </>
    )
}

export const HeaderTwoLarge = ({ copy, tanFooter }) => {
    return (
        <>
            <H2Large tanFooter={tanFooter}>{ReactHtmlParser(copy)}</H2Large>
        </>
    )
}

export const LargeText = ({ copy, hasDarkBackground }) => {
    return (
        <>
            <LargeTextContainer>
                <RightContainer>
                    <LargeP hasDarkBackground={hasDarkBackground}>
                        {ReactHtmlParser(copy)}
                    </LargeP>
                </RightContainer>
            </LargeTextContainer>
        </>
    )
}

export const Paragraph = ({ copy, isAbout, isTan, isCaseStudy }) => {
    return (
        <>
            <P isAbout={isAbout} isTan={isTan} isCaseStudy={isCaseStudy}>
                {ReactHtmlParser(copy)}
            </P>
        </>
    )
}

export const TextButton = ({ copy, hasLine, to, isExploreMore, isTan }) => {
    return (
        <>
            <TextCTAWrapper isExploreMore={isExploreMore}>
                <LinkWrapper>
                    <TextCTA to={to} hasLine={hasLine} isTan={isTan}>
                        {ReactHtmlParser(copy)} &nbsp; &rarr;
                    </TextCTA>
                </LinkWrapper>
            </TextCTAWrapper>
        </>
    )
}

//////// H1 STYLES /////////

export const H1 = styled.h1`
    font-family: ${fontFamily.manrope};
    font-weight: 500;
    font-size: ${fontSize.canon};
    line-height: 1.2;
    color: ${props => (props.isTan ? colors.pampas : colors.codGray)};
    margin-top: 0;
    margin-bottom: 0;

    @media ${deviceMinW.laptop} {
        font-size: ${fontSize.foolscap};
        line-height: 1.15;
    }
`

//////// H2 STYLES /////////

export const H2 = styled.h2`
    font-family: ${fontFamily.manrope};
    font-weight: 500;
    font-size: ${fontSize.doublePica};
    line-height: 1.3;
    color: ${props => (props.isTan ? colors.pampas : colors.codGray)};

    @media ${deviceMinW.laptop} {
        font-size: ${fontSize.trafalgar};
    }
`
export const H2Large = styled.h2`
    font-family: ${fontFamily.manrope};
    font-size: ${fontSize.doublePica};
    line-height: 1.3;
    font-weight: 500;
    color: ${props => (props.isTan ? colors.pampas : colors.codGray)};

    @media ${deviceMinW.tablet} {
        font-size: ${fontSize.trafalgar};
    }

    @media ${deviceMinW.laptop} {
        font-size: ${fontSize.canon};
    }

    ${props =>
        props.isFooter &&
        css`
            color: ${colors.pampas};
        `}

    ${({ tanFooter }) => css`
        color: ${tanFooter && colors.codGray};
    `}
`

//////// H3 STYLES /////////

export const H3 = styled.h3`
    font-family: ${fontFamily.manrope};
    font-size: ${fontSize.greatPrimer};
    line-height: 1.4;
    color: ${props => (props.isTan ? colors.pampas : colors.codGray)};
    font-weight: ${props => (props.isBold ? "500" : "400")};

    ${props =>
        props.isAbout &&
        css`
            font-size: ${fontSize.trafalgar};
            line-height: 1.4;
            padding-bottom: ${spacer.l};

            &:last-of-type {
                padding-bottom: 0;
            }
            @media ${deviceMinW.tablet} {
                font-size: ${fontSize.canon};
                line-height: 1.6;
            }
        `};
`
export const H3asH2 = styled.h3`
    font-family: ${fontFamily.manrope};
    font-size: ${fontSize.trafalgar};
    line-height: 1.2;
    color: ${colors.pampas};

    @media ${deviceMinW.laptop} {
        font-size: ${fontSize.canon};
    }
`

//////// CTA STYLES /////////

const TextCTAWrapper = styled.div``

export const TextCTA = styled(Link)`
    color: ${props => (props.isTan ? colors.pampas : colors.electricViolet)};
    font-family: ${fontFamily.manrope};
    font-weight: 500;
    font-size: ${fontSize.longPrimer};
    text-decoration: none;
    position: relative;

    ${({ hasLine, isTan }) =>
        hasLine &&
        css`
            display: flex;
            align-items: center;

            &::before {
                content: "";
                display: block;
                width: ${spacer.m};
                height: 1px;
                background: ${isTan ? colors.pampas : colors.electricViolet};
                position: relative;
                left: 0;
                top: 50%;
                z-index: -2;
                margin-right: ${spacer.m};
            }

            @media ${deviceMinW.mobileXL} {
                &::before {
                    width: ${spacer.l};
                }
            }
        `}
`
export const LinkWrapper = styled.div`
    display: inline-block;
`

//////// PARAGRAPH STYLES /////////

export const P = styled.p`
    color: ${props => (props.isTan ? colors.pampas : colors.codGray)};
    font-family: ${fontFamily.manrope};
    font-size: ${fontSize.bodyCopy};
    line-height: 1.8;
    /* ${props =>
        props.isHome &&
        css`
            font-size: ${fontSize.longPrimer};
            line-height: 1.3;

            @media ${deviceMinW.tablet} {
                font-size: ${fontSize.trafalgar};
            }

            @media ${deviceMinW.laptop} {
                font-size: ${fontSize.canon};
            }
        `} */
    ${props =>
        props.isAbout &&
        css`
            font-size: ${fontSize.trafalgar};
            line-height: 1.4;
            padding-bottom: ${spacer.l};

            &:last-of-type {
                padding-bottom: 0;
            }
            @media ${deviceMinW.tablet} {
                font-size: ${fontSize.canon};
                line-height: 1.6;
            }
        `};
`

export const LargeP = styled.p`
    font-family: ${fontFamily.manrope};
    font-size: ${fontSize.canon};
    line-height: 1.3;
    margin-bottom: 0;
    font-weight: 500;

    a {
        color: ${colors.pampas};
        text-decoration: none;
        border-bottom: 2px solid ${colors.pampas};
        @media ${deviceMinW.tablet} {
            border-bottom: 3px solid ${colors.pampas};
        }
    }

    @media ${deviceMinW.tablet} {
        line-height: 1.2;
    }

    @media ${deviceMinW.laptop} {
        font-size: ${fontSize.foolscap};
    }

    ${({ hasDarkBackground }) =>
        hasDarkBackground &&
        css`
            color: ${colors.pampas};
            background: ${colors.codGray};
            padding: ${spacer.xl} ${spacer.m};

            @media ${deviceMinW.laptop} {
                padding: ${spacer.xxl} ${spacer.xl};
            }
        `}
`

///////// TAGS /////////////////

export const Tag = styled.h2`
    color: ${props =>
        props.purpleTag ? colors.electricViolet : colors.codGray};
    font-size: ${fontSize.minion};
    text-transform: uppercase;
    font-family: ${fontFamily.manrope};
    margin-right: ${props => (props.blackTag ? "10px" : "0")};
    font-weight: 600;
`

//////// CONTAINERS /////////

const LargeTextContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`
