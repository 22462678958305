import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import { bool, func } from "prop-types"
import { get } from "lodash"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { fontSize } from "../../styles/fonts/fontStyle"
import { deviceMinW } from "../../styles/mediaQueries"
import { colors } from "../../styles/colors"
import logo from "../../images/descriptive-logo-purple.svg"
import lightLogo from "../../images/descriptive-logo-light.svg"
import Burger from "../Burger"
import Menu from "../Menu"
import { zIndex } from "../../styles/zIndex"
import { spacer } from "../../styles/sizes"
import { motion } from "framer-motion"
import useDocumentScrollThrottled from "../../hooks/useDocumentScrollThrottled"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks
} from "body-scroll-lock"

const duration = 0.5

const variants = {
    initial: {
        opacity: 0
    },
    enter: {
        opacity: 1,
        transition: {
            duration: duration,
            delay: duration,
            when: "beforeChildren",
            ease: "easeIn"
        }
    }
}

const Header = ({ menuOpen, setMenuOpen, location }) => {
    const navigationQuery = useStaticQuery(
        graphql`
            query {
                allNavigationJson {
                    nodes {
                        id
                        label
                        path
                    }
                }
            }
        `
    )

    const navigationItems = get(navigationQuery, "allNavigationJson.nodes", [])

    const path = location.pathname
    const aboutPath = path === "/about"
    const clientsPath = path === "/clients"
    const caseStudiesPath = path === "/case-studies"
    const curacaoPath = path === "/case-studies/curacao"
    const driveShackPath = path === "/case-studies/drive-shack"
    const rootPath = path === "/"

    console.log("curacaoPath", curacaoPath)
    console.log("driveShackPath", driveShackPath)

    // Trigger mobile navigation hook

    // Hide nav on scroll down, show on scroll up hook
    const [shouldHideHeader, setShouldHideHeader] = useState(false)

    const MINIMUM_SCROLL = 50
    const TIMEOUT_DELAY = 20

    useDocumentScrollThrottled(callbackData => {
        const { previousScrollTop, currentScrollTop } = callbackData
        const isScrolledDown = previousScrollTop < currentScrollTop

        const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

        console.log("isScrolledDown", isScrolledDown)
        console.log("isMinimumScrolled", isMinimumScrolled)

        setTimeout(() => {
            setShouldHideHeader(isScrolledDown && isMinimumScrolled)
        }, TIMEOUT_DELAY)
    })

    const hiddenStyle = shouldHideHeader ? "hidden" : ""

    /////////////////////////////////////////////////////////

    // Trigger background color change on scroll

    const [isBackground, setIsBackground] = useState(false)

    useScrollPosition(({ currPos }) => {
        // up means scrolling back toward the top
        // down is scrolling to the bottom of the page

        if (currPos.y <= -100 && !isBackground) {
            setIsBackground(true)
        } else if (currPos.y > -100 && isBackground) {
            setIsBackground(false)
        }
        console.log("currPos.y", currPos.y)
    })

    useEffect(() => {
        console.log("isBackground", isBackground)
        return () => {
            // cleanup
        }
    }, [isBackground])

    /////////////////////////////////////////////////////////

    // Body scroll lock for mobile //
    const menuRef = useRef(null)

    useEffect(() => {
        // disable when open
        if (menuOpen) {
            disableBodyScroll(menuRef.current, {
                allowTouchMove: element => element.id === "no-scroll-lock"
            })
        } else {
            // enable when closed
            enableBodyScroll(menuRef.current)
            clearAllBodyScrollLocks()
        }
        // listen for modalShown state
    }, [menuOpen])

    /////////////////////////////////////////////////////////

    return (
        <>
            <MobileNavContainer ref={menuRef}>
                <Menu
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    isOpen={menuOpen}
                />

                <Burger
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    aboutPath={aboutPath}
                    clientsPath={clientsPath}
                    caseStudiesPath={caseStudiesPath}
                />
            </MobileNavContainer>
            <HeaderBar
                isBackground={isBackground}
                className={isBackground ? "background-on" : ""}
                aboutPath={aboutPath}
                clientsPath={clientsPath}
                curacaoPath={curacaoPath}
                driveShackPath={driveShackPath}
                caseStudiesPath={caseStudiesPath}
            >
                <HeaderWrapper rootPath={rootPath}>
                    {!rootPath && (
                        <LeftNavBarWrapper>
                            <LogoLink to="/">
                                {aboutPath || caseStudiesPath || clientsPath ? (
                                    <NavLogo
                                        src={lightLogo}
                                        variants={variants}
                                        initial="initial"
                                        animate="enter"
                                        alt="Descriptive Logo"
                                        aria-label="Click here to go to the homepage."
                                    />
                                ) : (
                                    <NavLogo
                                        rootPath={rootPath}
                                        src={logo}
                                        variants={variants}
                                        initial="initial"
                                        animate="enter"
                                        alt="Descriptive Logo"
                                        aria-label="Click here to go to the homepage"
                                    />
                                )}
                            </LogoLink>
                        </LeftNavBarWrapper>
                    )}
                    <RightNavBarWrapper
                        aria-label="Primary navigation"
                        className={`header ${hiddenStyle}`}
                        role="navigation"
                    >
                        <DesktopNavContainer
                            variants={variants}
                            initial="initial"
                            animate="enter"
                        >
                            {navigationItems.map(navLink => {
                                return (
                                    <NavListItem key={navLink.id}>
                                        <NavLink
                                            to={navLink.path}
                                            activeClassName="active"
                                            aboutPath={aboutPath}
                                            clientsPath={clientsPath}
                                            caseStudiesPath={caseStudiesPath}
                                        >
                                            {navLink.label}
                                        </NavLink>
                                    </NavListItem>
                                )
                            })}
                        </DesktopNavContainer>
                    </RightNavBarWrapper>
                </HeaderWrapper>
            </HeaderBar>
        </>
    )
}

Header.propTypes = {
    showLogo: bool.isRequired,
    setShowLogo: func.isRequired
}

export default Header

const HeaderBar = styled.header`
    height: 7rem;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: ${zIndex.desktopNav};
    transition: background-color 0.3s easeIn;
    will-change: background-color;

    &.background-on {
        background-color: ${colors.pampas};
        ${({ aboutPath, clientsPath, caseStudiesPath }) => css`
            background-color: ${aboutPath === true && colors.electricViolet};
            background-color: ${caseStudiesPath === true && colors.codGray};
            background-color: ${clientsPath === true && colors.persianIndigo};
        `};
    }

    @media ${deviceMinW.tablet} {
        &.background-on {
            background-color: transparent;
        }
    }
`

const HeaderWrapper = styled.div`
    display: flex;
    padding: 0 ${spacer.m};
    max-width: 1400px;
    justify-content: center;
    margin: auto;
    height: 7rem;
    width: 100%;
    z-index: ${zIndex.desktopNav};

    @media ${deviceMinW.tablet} {
        padding: 0 ${spacer.l};
    }
`

const LeftNavBarWrapper = styled(motion.div)`
    display: flex;
    position: relative;
    z-index: ${zIndex.desktopNav};
    flex-grow: 1;
`

const RightNavBarWrapper = styled.nav`
    display: flex;
    align-items: left;
    flex: 2;
    justify-content: flex-end;
    z-index: ${zIndex.burger};
    transform: translateY(0);
    transition: transform 0.3s ease-out;
    will-change: transform;
    align-items: center;
    &.hidden {
        transform: translateY(-200%);
    }
`

const DesktopNavContainer = styled(motion.ul)`
    display: none;

    @media ${deviceMinW.tablet} {
        display: flex;
        list-style: none;
        transform: translateY(0);
        transition: transform 0.3s ease-out;
        position: fixed;
        will-change: transform;
    }
`

const NavListItem = styled(motion.li)`
    padding-right: ${spacer.m};
    padding-left: ${spacer.m};

    &:first-of-type {
        padding-left: 0;
    }

    &:last-of-type {
        padding-right: 0;
    }
`

const NavLink = styled(Link)`
    display: flex;
    font-family: ${fontFamily.manrope};
    font-weight: 500;
    color: ${colors.codGray};
    text-decoration: none;
    font-size: ${fontSize.longPrimer};
    align-items: center;
    position: relative;
    white-space: nowrap;
    transition: color 0.5s ease-in;
    will-change: color;

    ${({ aboutPath, caseStudiesPath, clientsPath }) => css`
        color: ${aboutPath && colors.pampas};
        color: ${caseStudiesPath && colors.pampas};
        color: ${clientsPath && colors.pampas};
    `};

    &.active {
        &:before {
            content: "";
            width: ${spacer.xs};
            height: ${spacer.xs};
            border-radius: ${spacer.xs};
            background-color: ${({ aboutPath, caseStudiesPath, clientsPath }) =>
                aboutPath || caseStudiesPath || clientsPath
                    ? colors.pampas
                    : colors.electricViolet};
            position: absolute;
            left: -1.5rem;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            transition: background-color 0.5s ease-in;
            will-change: background-color;
        }
    }
`

export const LogoLink = styled(Link)``

const NavLogo = styled(motion.img)`
    width: 10rem;
    position: fixed;
    transform: translateY(88%);

    @media ${deviceMinW.tablet} {
        width: 13rem;
        transform: translateY(50%);
    }
`
const MobileNavContainer = styled.div`
    display: flex;
    align-items: center;
`
