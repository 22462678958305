import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { spacer } from "../../styles/sizes"
import { deviceMinW } from "../../styles/mediaQueries"

const VerticalSpacer = ({ size }) => {
    return <SpacerContainer size={size} />
}

VerticalSpacer.propTypes = {
    children: PropTypes.node,
    size: PropTypes.oneOf([
        spacer.xxs,
        spacer.xs,
        spacer.s,
        spacer.m,
        spacer.l,
        spacer.xl,
        spacer.xxl,
        spacer.xxxl
    ])
}

export default VerticalSpacer

const SpacerContainer = styled.div`
    display: flex;
    position: relative;

    ${props =>
        props.size === "xxs" &&
        css`
            height: ${spacer.xxs};
            width: ${spacer.xxs};
            flex-basis: ${spacer.xxs};
        `}
    ${props =>
        props.size === "xs" &&
        css`
            height: ${spacer.xs};
            width: ${spacer.xs};
            flex-basis: ${spacer.xs};
        `}
    ${props =>
        props.size === "s" &&
        css`
            height: ${spacer.s};
            width: ${spacer.s};
            flex-basis: ${spacer.s};
        `}
    ${props =>
        props.size === "m" &&
        css`
            height: ${spacer.m};
            width: ${spacer.m};
            flex-basis: ${spacer.m};
        `}
    ${props =>
        props.size === "l" &&
        css`
            height: ${spacer.l};
            width: ${spacer.l};
            flex-basis: ${spacer.l};
        `}
    ${props =>
        props.size === "xl" &&
        css`
            height: ${spacer.l};
            width: ${spacer.l};
            flex-basis: ${spacer.l};

            @media ${deviceMinW.mobileXL} {
                height: ${spacer.xl};
                width: ${spacer.xl};
                flex-basis: ${spacer.xl};
            }
        `}
    ${props =>
        props.size === "xxl" &&
        css`
            height: ${spacer.xl};
            width: ${spacer.xl};
            flex-basis: ${spacer.xl};

            @media ${deviceMinW.mobileXL} {
                height: ${spacer.xxl};
                width: ${spacer.xxl};
                flex-basis: ${spacer.xxl};
            }
        `};
    ${props =>
        props.size === "xxxl" &&
        css`
            height: 20rem;
            width: 20rem;
            flex-basis: 20rem;

            @media ${deviceMinW.mobileXL} {
                height: ${spacer.xxxl};
                width: ${spacer.xxxl};
                flex-basis: ${spacer.xxxl};
            }
        `};
`
