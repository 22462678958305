import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { colors } from "../../styles/colors"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { fontSize } from "../../styles/fonts/fontStyle"
import { useStaticQuery, graphql } from "gatsby"
import { get } from "lodash"
import { spacer } from "../../styles/sizes"

const SocialLinks = ({ tanFooter }) => {
    const socialLinksQuery = useStaticQuery(
        graphql`
            query {
                allSocialLinksJson {
                    nodes {
                        social {
                            id
                            title
                            url
                        }
                    }
                }
            }
        `
    )

    const [socialInfo] = get(socialLinksQuery, "allSocialLinksJson.nodes", [])
    const { social = [] } = socialInfo

    return (
        <>
            <SocialInfoWrapper>
                {social.map(socialNetwork => (
                    <SocialNetworkWrapper key={socialNetwork.id}>
                        <SocialNetwork
                            href={socialNetwork.url}
                            tanFooter={tanFooter}
                            target="_blank"
                            aria-label="Click here to visit Descriptive's social media pages."
                        >
                            {socialNetwork.title}
                        </SocialNetwork>
                    </SocialNetworkWrapper>
                ))}
            </SocialInfoWrapper>
        </>
    )
}
export default SocialLinks

const SocialInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

const SocialNetworkWrapper = styled.div`
    padding-right: ${spacer.m};

    &:last-of-type {
        padding-right: 0;
    }
`

const SocialNetwork = styled.a`
    font-family: ${fontFamily.manrope};
    font-weight: 600;
    font-size: ${fontSize.minion};
    text-decoration: none;
    color: ${colors.codGray};

    ${({ tanFooter }) => css`
        color: ${tanFooter && colors.pampas};
    `};
`
